export const role = {
  ADMIN: "ADMIN",
  TECNICO: "TECNICO",
  FRANQUEADO: "FRANQUEADO",
};

export const roleNames = {
  [role.ADMIN]: "Administrador",
  [role.TECNICO]: "Tecnico",
  [role.FRANQUEADO]: "Franqueado",
};

export const roleListSelect = [
  { code: [role.ADMIN], text: roleNames[role.ADMIN] },
  { code: [role.TECNICO], text: roleNames[role.TECNICO] },
  { code: [role.FRANQUEADO], text: roleNames[role.FRANQUEADO] },
];
