<template>
  <div
    class="tw-bg-gray-100 tw-rounded-b-md tw-mt-3 tw-flex tw-justify-items-center tw-justify-center tw-text-right"
  >
    <div class="tw-m-1">
      <button
        class="tw-p-2 tw-bg-transparent tw-rounded-full hover:tw-bg-gray-100 tw-fill-current tw-group tw-text-white"
        @click="EditUser()"
      >
        <v-icon
          class="tw-text-gray-800 tw-fill-current tw-stroke-current group-hover:tw-text-pink-700"
          >mdi-pencil</v-icon
        >
      </button>
    </div>
    <div class="tw-m-1">
      <button
        class="tw-p-2 tw-bg-transparent tw-rounded-full hover:tw-bg-gray-100 tw-fill-current tw-group tw-text-white"
        @click="RemoveUser()"
      >
        <v-icon
          class="tw-text-gray-800 tw-fill-current tw-stroke-current group-hover:tw-text-pink-700"
          >mdi-delete</v-icon
        >
      </button>
    </div>
    <div class="tw-m-1">
      <button
        class="tw-p-2 tw-bg-transparent tw-rounded-full hover:tw-bg-gray-100 tw-fill-current tw-group tw-text-white"
        @click="ResetPassword()"
      >
        <v-icon
          class="tw-text-gray-800 tw-fill-current tw-stroke-current group-hover:tw-text-pink-700"
          >mdi-lock-reset</v-icon
        >
      </button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    EditUser() {
      this.$emit("edit");
    },
    RemoveUser() {
      this.$emit("remove");
    },
    ResetPassword() {
      this.$emit("resetpass");
    },
  },
};
</script>

<style></style>
