<template>
  <div
    id="item"
    class="tw-items-baseline tw-inline-block tw-mx-auto tw-my-2 sm:tw-m-3 sm:tw-w-60 tw-w-5/6 tw-shadow tw-border-gray-200 tw-border tw-rounded-md tw-bg-white"
  >
    <div class="tw-p-6">
      <div class="tw-font-bold tw-text-gray-800 tw-text-sm">
        {{ item.nome }}
      </div>
      <div class="tw-break-words tw-text-sm tw-text-gray-400">
        {{ item.email }}
      </div>
      <div>
        {{ getNamePermissao(item.role) }}
      </div>
    </div>
    <vOptionsUser
      @edit="modalEditUser = true"
      @remove="modalDeleteUser = true"
      @resetpass="modalResetPassword = true"
    />

    <v-dialog persistent v-model="modalResetPassword" max-width="500px">
      <div class="tw-p-6 tw-px-7 tw-bg-white">
        <div class="tw-grid tw-grid-cols-4">
          <div class="tw-text-2xl tw-font-light tw-col-span-3">
            Redefinição de senha
          </div>
          <div class="tw-col-span-1 tw-text-right">
            <v-btn text @click="modalResetPassword = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="tw-font-light tw-text-base tw-pb-4">
          Será enviado um email de recuperação de senha para
          <b>{{ item.email }}</b>
        </div>
        <div>
          <div class="tw-text-right">
            <button
              class="tw-px-4 tw-py-3 tw-bg-primary tw-rounded-md tw-text-white tw-fill-current"
              @click="reset_senha(item.email)"
            >
              <div v-if="loading">
                <v-icon class="tw-animate-spin">mdi-loading</v-icon>
              </div>
              <div v-else>ENVIAR</div>
            </button>
          </div>
        </div>
      </div>
    </v-dialog>

    <v-dialog persistent v-model="modalEditUser" max-width="500px">
      <div class="tw-p-6 tw-px-7 tw-bg-white">
        <div class="tw-grid tw-grid-cols-4">
          <div class="tw-text-2xl tw-font-light tw-col-span-3">
            Editar Cadastro
          </div>
          <div class="tw-col-span-1 tw-text-right">
            <v-btn text @click="modalEditUser = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
        <!-- <div class="tw-font-light tw-text-base tw-pb-4">
            Será enviado um email de recuperação de senha para <b>{{ item.email }}</b>
          </div> -->
        <v-text-field label="Nome" id="id" v-model="nome" />
        <v-select
          :items="listaRole"
          v-model="role"
          item-value="code"
          item-text="text"
          label="Função"
        ></v-select>
        <div>
          <div class="tw-text-right">
            <button
              class="tw-px-4 tw-py-3 tw-bg-primary tw-rounded-md tw-text-white tw-fill-current"
              @click="editar_user(item.uId)"
            >
              <div v-if="loading">
                <v-icon class="tw-animate-spin">mdi-loading</v-icon>
              </div>
              <div v-else>Salvar</div>
            </button>
          </div>
        </div>
      </div>
    </v-dialog>

    <v-dialog persistent v-model="modalDeleteUser" max-width="500px">
      <div class="tw-p-6 tw-px-7 tw-bg-white">
        <div class="tw-grid tw-grid-cols-4">
          <div class="tw-text-2xl tw-font-light tw-col-span-3">
            Deletar usuário
          </div>
          <div class="tw-col-span-1 tw-text-right">
            <v-btn text @click="modalDeleteUser = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="tw-font-light tw-text-base tw-pb-4">
          Deseja realmente deletar o usuário <b>{{ item.nome }}</b>
        </div>
        <div>
          <div class="tw-text-right">
            <button
              class="tw-px-4 tw-py-3 tw-bg-primary tw-rounded-md tw-text-white tw-fill-current"
              @click="deletarUsuario(item)"
            >
              <div v-if="loading">
                <v-icon class="tw-animate-spin">mdi-loading</v-icon>
              </div>
              <div v-else>DELETAR</div>
            </button>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { doc, updateDoc, deleteDoc } from "firebase/firestore";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { db } from "@/service/firebase";
import service from "@/service/axios.js";
import { roleListSelect, roleNames } from "@/constants/role";
import vOptionsUser from "./options.vue";

export default {
  components: {
    vOptionsUser,
  },
  data() {
    return {
      nome: this.item.nome,
      role: this.item.role,
      listaRole: [],
      modalResetPassword: false,
      modalEditUser: false,
      modalDeleteUser: false,
      loading: false,
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  beforeMount() {
    this.listaRole = roleListSelect;
  },
  methods: {
    getNamePermissao(PERM) {
      return roleNames[PERM];
    },
    editar_user(uId) {
      updateDoc(doc(db, "users", uId), {
        nome: this.nome,
        role: this.role,
      })
        .then(() => {
          this.modalEditUser = false;
          this.$emit("update");
          this.$root.sucessoModal("Usuário salvo com sucesso!");
        })
        .catch((error) => {
          const errorCode = error.code;
          console.log(errorCode);
          this.$root.errorModal("Ocorreu um erro inesperado.");
        });
    },
    deletarUsuario(user) {
      service
        .deleteUserAPI(user.uId)
        .then(() => {
          this.removerDadosUser(user.uId);
        })
        .catch((err) => {
          if (err.data.error.message == "INVALID_ID_TOKEN") {
            this.entrarComEsseUser(user);
          } else {
            this.$root.errorModal(err);
          }
        });
    },
    removerUsuarioViaToken(token, user) {
      service
        .deleteUserAPI(token)
        .then(() => {
          this.removerDadosUser(user.uId);
        })
        .catch((err) => {
          if (err.data.error.message == "INVALID_ID_TOKEN") {
            this.entrarComEsseUser(user);
          } else {
            this.$root.errorModal(err);
            this.modalDeleteUser = false;
            this.$emit("update");
          }
        });
    },
    entrarComEsseUser(user) {
      service
        .logarUsuarioViaApi(user.email, user.senha)
        .then((resp) => {
          this.removerUsuarioViaToken(resp.data.idToken, user);
        })
        .catch((err) => {
          console.error(err);
          this.$root.errorModal("Ocorreu um erro incomum");
          this.modalDeleteUser = false;
          this.$emit("update");
        });
    },
    removerDadosUser(userID) {
      deleteDoc(doc(db, "users", userID))
        .then(() => {
          this.modalDeleteUser = false;
          this.$emit("update");
          this.$root.sucessoModal("Usuário deletado com sucesso!");
        })
        .catch((error) => {
          const errorCode = error.code;
          console.log(errorCode, error);
          this.$root.errorModal("Ocorreu um erro inesperado.");
        });
    },
    reset_senha(email) {
      const auth = getAuth();
      sendPasswordResetEmail(auth, email)
        .then(() => {
          this.modalResetPassword = false;
          this.$root.sucessoModal("Email para redefinição de senha enviado!");
        })
        .catch((error) => {
          const errorCode = error.code;
          console.log(errorCode);

          switch (errorCode) {
            case "auth/user-not-found":
              this.$root.errorModal("Usuário não encontrado.");
              break;

            case "auth/invalid-email":
              this.$root.errorModal("Email inválido.");
              break;

            case "auth/missing-email":
              this.$root.errorModal("Digite um email!");
              break;
          }
        });
    },
  },
};
</script>

<style></style>
