import axios from "axios";
import { FIREBASE_API_KEY } from "@/constants/keys";
const base = "https://identitytoolkit.googleapis.com/v1/";

export default {
  createUserAPI(email, senha) {
    let data = {
      email: email,
      password: senha,
      returnSecureToken: true,
    };
    return axios.post(`${base}accounts:signUp?key=${FIREBASE_API_KEY}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  async logarUsuarioViaApi(email, senha) {
    let data = {
      email: email,
      password: senha,
      returnSecureToken: true,
    };
    return axios.post(
      `${base}accounts:signInWithPassword?key=${FIREBASE_API_KEY}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  async deleteUserAPI(userToken) {
    let data = {
      idToken: `${userToken}`,
    };
    return axios.post(`${base}accounts:delete?key=${FIREBASE_API_KEY}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
};
