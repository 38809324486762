<template>
  <div>
    <div class="tw-grid tw-grid-cols-6 tw-items-center">
      <div class="sm:tw-col-span-4 tw-col-span-6">
        <div class="tituloContent sm:tw-text-left tw-text-center">Usuários</div>
      </div>
      <div class="sm:tw-col-span-2 sm:tw-p-0 tw-p-4 tw-col-span-6">
        <div class="sm:tw-text-right tw-text-center">
          <button
            class="tw-text-white tw-py-2 tw-px-5 tw-rounded-md tw-mr-0 sm:tw-mr-4 tw-bg-pink-600 hover:tw-bg-pink-500 tw-transition-colors tw-duration-500"
            @click="modalNovoUser = true"
          >
            Cadastrar
          </button>
        </div>
      </div>
    </div>
    <div class="tw-px-5 tw-mx-0">
      <div
        class="tw-bg-white tw-transition-colors tw-flex tw-align-middle tw-rounded-md tw-ring-2 tw-ring-gray-200"
      >
        <v-icon class="tw-items-center tw-fill-current tw-p-3 tw-text-gray-500">
          mdi-magnify
        </v-icon>
        <input
          type="text"
          v-model="searchName"
          placeholder="Buscar nome de usuário"
          class="tw-p-3 tw-px-0 tw-w-full tw-outline-none tw-rounded-tr-md tw-rounded-br-md group-focus:tw-ring-1"
        />
      </div>
    </div>
    <div
      class="tw-block mt-3 sm:tw-flex sm:tw-flex-wrap tw-items-baseline tw-text-center tw-justify-center"
    >
      <vUserCard
        @update="getUsers()"
        :key="index"
        v-for="(item, index) in getList"
        :item="item"
      />
    </div>
    <vNovo
      @update="getUsers()"
      @close="modalNovoUser = false"
      v-if="modalNovoUser"
    />
  </div>
</template>

<script>
import { collection, getDocs } from "firebase/firestore";
import { db } from "@/service/firebase";
import vNovo from "./novo.vue";
import vUserCard from "./UserCard/index.vue";

export default {
  components: {
    vNovo,
    vUserCard,
  },
  data() {
    return {
      listaUsers: [],
      searchName: "",
      modalNovoUser: false,
    };
  },
  mounted() {
    this.getUsers();
  },
  computed: {
    getList() {
      return this.listaUsers.filter((item) => {
        let nameList = item.nome.toUpperCase();
        let searchName = this.searchName.toUpperCase();
        return nameList.match(searchName);
      });
    },
  },
  methods: {
    async getUsers() {
      this.listaUsers = [];
      const querySnapshot = await getDocs(collection(db, "users"));
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        let user = doc.data();
        this.listaUsers.push({
          email: user.email,
          franqueado_id: user.franqueado_id,
          nome: user.nome,
          role: user.role,
          uId: doc.id,
          senha: user.senha,
        });
      });
    },
  },
};
</script>

<style></style>
