<template>
  <v-dialog persistent v-model="dialog" max-width="500px">
    <div class="tw-p-6 tw-px-7 tw-bg-white">
      <div class="tw-grid tw-grid-cols-4">
        <div class="tw-text-2xl tw-font-light tw-col-span-3">
          Cadastro de usuário
        </div>
        <div class="tw-col-span-1 tw-text-right">
          <v-btn text @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
      <div>
        <v-text-field label="Nome" id="id" v-model="include.nome" />
        <v-text-field label="Email" id="id" v-model.trim="include.email" />
        <div class="tw-flex tw-flex-row tw-items-center">
          <div class="tw-flex-1">
            <v-text-field
              :type="computedTypePass"
              label="Senha"
              id="id"
              v-model.trim="include.senha"
            />
          </div>
          <div class="tw-flex-none tw-pl-5">
            <v-btn color="white" @click="viewPass = !viewPass">
              <v-icon v-if="viewPass">mdi-eye</v-icon>
              <v-icon v-else>mdi-eye-off</v-icon>
            </v-btn>
          </div>
        </div>
        <v-select
          :items="listaRole"
          v-model="include.role"
          item-value="code"
          item-text="text"
          label="Função"
        ></v-select>
        <div class="tw-text-right">
          <button
            class="tw-px-4 tw-py-3 tw-bg-primary tw-rounded-md tw-text-white tw-fill-current"
            @click="criarUser()"
          >
            <div v-if="loading">
              <v-icon class="tw-animate-spin">mdi-loading</v-icon>
            </div>
            <div v-else>CRIAR</div>
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import serviceAPI from "@/service/axios";
import { roleListSelect } from "@/constants/role";
import { doc, setDoc } from "firebase/firestore";
import { db } from "@/service/firebase";
export default {
  data() {
    return {
      dialog: true,
      listaRole: [],
      loading: false,
      viewPass: false,
      include: {
        email: "",
        senha: "",
        nome: "",
        role: "",
        franqueado: 1,
      },
    };
  },
  beforeMount() {
    this.listaRole = roleListSelect;
  },
  computed: {
    computedTypePass() {
      if (this.viewPass) return "text";
      else return "password";
    },
  },
  methods: {
    async cadastrarDados(uid, lockUser) {
      await setDoc(doc(db, "users", `${uid}`), {
        email: lockUser.email,
        senha: lockUser.senha,
        nome: lockUser.nome,
        role: lockUser.role,
        franqueado_id: lockUser.franqueado,
        status: "ATIVO",
      });
      this.$root.sucessoModal("Cadastrado com sucesso!");
      this.loading = false;
      this.$emit("update");
      this.$emit("close");
    },
    validaEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    verificaCampos() {
      let toggleToErro = false;
      let errorList = [];
      if (!this.include.nome || this.include.nome.length < 3) {
        toggleToErro = true;
        errorList.push({
          mensagem: "O campo nome deve conter pelo menos 3 letras",
        });
      }

      if (!this.validaEmail(this.include.email)) {
        toggleToErro = true;
        errorList.push({
          mensagem: "Email está inválido",
        });
      }

      if (!this.include.senha || this.include.senha.length < 3) {
        toggleToErro = true;
        errorList.push({
          mensagem: "A senha deve conter pelo menos 6 digitos ou letras",
        });
      }

      if (!this.include.role || this.include.role == "") {
        toggleToErro = true;
        errorList.push({
          mensagem: "Função não definida",
        });
      }

      if (toggleToErro) {
        if (errorList.length == 1) {
          this.$root.atencaoModal("Verifique seguinte campo", errorList);
        } else {
          this.$root.atencaoModal("Verifique os seguintes campos", errorList);
        }
        return false;
      }
      return true;
    },
    criarUser() {
      this.loading = true;
      if (!this.verificaCampos()) {
        this.loading = false;
        return false;
      }
      const lockUser = { ...this.include };
      serviceAPI
        .createUserAPI(lockUser.email, lockUser.senha)
        .then((resp) => {
          console.log("User sing-up...");
          this.cadastrarDados(resp.data.localId, lockUser);
        })
        .catch((err) => {
          this.loading = false;
          let mensagem = err;
          if (err.data.error.message == "EMAIL_EXISTS") {
            mensagem = "Esse email já existe no sistema.";
          }
          this.$root.errorModal(mensagem);
        });
    },
  },
};
</script>

<style></style>
